import React, { useEffect } from 'react'
import {
  Card,
  Container,
  FilledButton,
  List,
  ListItem,
  OutlinedButton,
} from '@jsluna/react'
import { ErrorCircle } from '@jsluna/icons'
import parseErrorStack from '../../utils/errors'
import Loading from './Loading'
interface IError<T> {
  message: string
  actions?: [
    {
      handler: () => T
      title: string
    }
  ]
}

const onReload = () => {
  window.location.href = window.location.origin
}

function ErrorRefresh<T>({ message, actions }: IError<T>) {
  useEffect(() => {
    const errorStack = parseErrorStack(new Error(message))
    if (errorStack && errorStack.length > 0) {
      console.log(message, JSON.stringify(errorStack, null, 2))
    }
  }, [message, actions])


  return (
    <>
      <div className='c-common-main-view-content'>
        <Container className='ln-u-text-align-center ln-u-margin-top*2' id='errorRefresh' soft>
          <Card className='ln-u-margin-sides'>
            <div className='ln-u-margin-top*4'>
              <ErrorCircle size='large' className='c-common-error_icon ln-u-display-6-fixed' />

              <div className='c-common-error_primaryMessage ln-u-display-4-fixed'>
                Something wrong.
              </div>

              <div className='c-common-error_secondaryMessage'>
                {(message) ? <div data-testid='errorMessage'>{message}</div> : <Loading />}
              </div>

              <div className='c-common-error_secondaryMessage'>
                Please try again.
              </div>

              <div className='ln-u-soft'>
                <List type='matrix' spaced className='ln-u-margin-top*4'>
                  <ListItem>
                    <FilledButton
                      className='ln-u-soft-sides'
                      onClick={() => onReload()}
                    >
                      Refresh
                    </FilledButton>
                  </ListItem>
                  {actions && actions.length > 0 && (
                    actions.map((x, i) => (
                      <ListItem key={i}>
                        <OutlinedButton
                          className='ln-u-soft-sides'
                          onClick={() => x.handler()}
                        >
                          {x.title}
                        </OutlinedButton>
                      </ListItem>
                    ))
                  )}
                </List>
              </div>
            </div>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default ErrorRefresh
