import { Moment } from 'moment'
import React from 'react'
import AppTooltip from '../common/components/Tooltip/AppTooltip'

export const allDefrostPizzaTooltipMessages = () =>
  <>
    {forecastPriorAndTodayDefrostToolTipMessage()} <br /><br />
    <b>You defrosted</b><br />
    Enter the number of pizzas you’ve defrosted today.
  </>

export const allPizzaTooltipMessages = () =>
  <>
    {forecastPriorAndTodayToolTipMessage()} <br /><br />
    <b>You made</b><br />
    Enter the number of pizzas you’ve made today.
  </>

export const forecastPriorAndTodayToolTipMessage = () =>
  <>
    <b>To make today</b><br />
    {toMakeTodayMessage()}<br /><br />
    <b>Total Made</b><br />
    Your team has made this number of pizzas today.
  </>

export const forecastPriorAndTodayDefrostToolTipMessage = () =>
  <>
    <b>To defrost today</b><br />
    {toDefrostTodayMessage()}<br /><br />
    <b>Total defrosted</b><br />
    Your team has defrosted this number of pizzas today.
  </>

export const toMakeTodayMessage = () =>
  <>
    Your team needs to make this number of pizzas today.<br /><br />
    This is in addition to any pizzas you already have on hand.
  </>

export const toDefrostTodayMessage = () =>
  <>
    Your team needs to defrost this number of pizzas today.
  </>

export const salesForecastTooltipMessage =
  'This figure is calculated using historic sales data from your store for the last year.'

export const formatPizzaName = (pizzaName: string) => (
  // eslint-disable-next-line no-useless-escape
  pizzaName.replace(/10"|14"|(Cold)|Thin & Crispy|T&C|Deep Pan|[\(\)]/gi, '')
)

export const toMakeTodayTitle = 'To make today'
export const totalMadeTitle = 'Total made'
export const salesForecastTitle = 'Sales Forecast'
export const youMadeTitle = 'You made'

export const toDefrostTodayTitle = 'To defrost today'
export const totalDefrostedTitle = 'Total defrosted'
export const youDefrostedTitle = 'You defrosted'

export const toDefrostTodayToolTip = <AppTooltip value={toDefrostTodayMessage()} />
export const totalDefrostedToolTip = <AppTooltip message='Your team has defrosted this number of pizzas today.' />
export const youDefrostedToolTip = <AppTooltip message='Enter the number of pizzas you’ve defrosted today.' />

export const toMakeTodayToolTip = <AppTooltip value={toMakeTodayMessage()} />
export const totalMadeToolTip = <AppTooltip message='Your team has made this number of pizzas today.' />
export const overallDefrostToolTip = <AppTooltip value={allDefrostPizzaTooltipMessages()} />
export const overallToolTip = <AppTooltip value={allPizzaTooltipMessages()} />
export const forecastPriorAndTodayTooltip = <AppTooltip value={forecastPriorAndTodayToolTipMessage()} />
export const youMadeToolTip = <AppTooltip message='Enter the number of pizzas you’ve made today.' />
export const salesForecastToolTip = <AppTooltip message={salesForecastTooltipMessage} />
export const stockOnHandTooltip =
  <AppTooltip message=' This is the total number of made-up pizzas on display and in backup areas.' />

export const getDateOptions = (dates: Moment[]) =>
  dates.map((date: Moment, i: number) => {
    let dateText = date.format('ddd Do MMMM')

    if (dates.length === 15) {
      if (i === 14) {
        dateText = dateText.concat(' (Today)')
      }
    } else if (i === 6) {
      dateText = dateText.concat(' (Today)')
    }

    return (
      <option key={i} value={i}>{dateText}</option>
    )
  })

export const dateTimeInRange = (value: string) => {
  const dateTimeRanges = value.split(';')
  const hour = new Date().getHours()
  let result = false

  for (const dateTimeRange of dateTimeRanges) {
    const dtr = dateTimeRange.split(',')

    const dd = parseInt(dtr[0], 10)
    const start = parseInt(dtr[1], 10)
    const end = parseInt(dtr[2], 10)

    if (new Date().getDay() === dd && hour >= start && hour < end) {
      result = true
      break
    }
  }

  return result
}
