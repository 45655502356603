import React, { useContext, useEffect, useState } from 'react'
import { Container, GridWrapper, GridItem, Tabs, TabLink, TabPanel, OutlinedButton } from '@jsluna/react'
import moment from 'moment'
import { isFeatureEnabled } from '../../../common/api/featureApi'
import { useApiClient } from '../../../common/AppContext/appContext'
import ErrorRefresh from '../../../common/components/ErrorRefresh'
import { ErrorMessage } from '../../../common/enums/ErrorMessage'
import Header from '../../../common/components/Header'
import { GetHeaderMainMenus, headerMainMenus, headerPlannerTitles } from '../../../common/enums/HeaderItems'
import Loading from '../../../common/components/Loading'
import TutorialWrapper from '../../../common/components/TutorialWrapper'
import { setPlanner } from '../../../common/Context/commonDispatch'
import { Context } from '../../../common/Context/context'
import { Feature } from '../../../common/enums/FeatureEnum'
import { Menu } from '../../../common/enums/MenuEnum'
import { PlannerName } from '../../../common/enums/PlannerNameEnum'
import { IResult } from '../../../common/types/IResult'
import { getUserStore } from '../../../utils/localStore'
import {
  createCabinetStatus,
  createCounterTemperatures,
  getCounters,
} from '../../api/counterApi'
import { AlertMessageKey } from '../../enums/AlertMessageKey'
import { BatchTray, CabinetWindow, TurboShelf } from '../../enums/TemperatureCheckPosition'
import { ITemperatureData } from '../../types/ITemperatureData'
import { ICounterTemperature } from '../../types/ICounterTemperature'
import ICounterCheck from '../../types/ICounterCheck'
import {
  getCounterByNumber,
  hasAnyCounterToFirstCheckFrom,
  isAllTemperatureChecked,
  validCounterTemperatures,
  updateCounterCheckStatusByTime,
  afternoonHour,
} from '../../utils/hotFoodUtils'
import { ICreateCounterTemperaturesResponse } from '../../types/ICreateCounterTemperaturesResponse'
import CabinetStatusModal from './CabinetStatusModal'
import CounterCard from './CounterCard'
import CounterAlertModal from './CounterAlertModal'
import CounterInstruction from './CounterInstruction'
import PostSubmitMessageCard from './PostSubmitMessageCard'
import CounterManualInputModal from './CounterManualInputModal'

interface ICounterAlertConfig {
  alertKey?: AlertMessageKey
  counterNumber?: number
  isTurboServe?: boolean
  opened: boolean
}

const CounterTemperatureView = () => {
  const storeId = getUserStore().storeId
  const tabs: Array<{ name: string; key: string; testid: string }> = []

  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [activeTab, setActiveTab] = React.useState('cabinets')
  const [isTurboServe, setIsTurboServe] = useState(activeTab === 'turbo-serve')
  const [cabinets, setCabinets] = useState<ICounterCheck[]>([])
  const [shelves, setShelves] = useState<ICounterCheck[]>([])
  const [isFirstCheck, SetIsFirstCheck] = useState<boolean>(true)
  const [isForecastingEnabled, setIsForecastingEnabled] = useState<boolean | null>(null)
  const [submitBufferData, SetSubmitBufferData] = useState<ITemperatureData[]>([])
  const [hasServeOverAction, setHasServeOverAction] = useState(true)
  const [hasTurboServeAction, setHasTurboServeAction] = useState(false)
  const [isTurboServeInstantSubmissionMessage, setIsTurboServeInstantSubmissionMessage] = useState(false)
  const [alertConfig, setAlertConfig] = useState<ICounterAlertConfig>({
    alertKey: AlertMessageKey.NONE,
    counterNumber: 1,
    isTurboServe: false,
    opened: false,
  })
  const [hasTurboServeTab, setHasTurboServeTab] = useState(false)
  const [hasServeOverTab, setHasServeOverTab] = useState(false)
  const [faultyOrFixedCabinet, setFaultyOrFixedCabinet] = useState<ICounterCheck | undefined>(undefined)
  const [isManualInputModalOpen, setIsManualInputModalOpen] = useState(false)
  const [hotFoodProbeFeature, setHotFoodProbeFeature] = useState(false)
  const [isBluetoothReading, setIsBluetoothReading] = useState(true)
  const [tutorialToken, setTutorialToken] = useState<string | null>(null)
  const [headerNavigationItems, setHeaderNavigationItems] = useState(headerMainMenus.hotfoodcounterNavs)

  let varCabinets: ICounterCheck[] = []
  let varShelves: ICounterCheck[] = []

  const apiClient = useApiClient()
  const { state, dispatch } = useContext(Context)

  useEffect(() => {
    GetHeaderMainMenus(apiClient, getUserStore().storeId, PlannerName.HotFood).then((res) => setHeaderNavigationItems(res))
  }, [])

  const loadDataProcess = (refreshCabinetsStatus: boolean, refreshShelvesStatus: boolean) => {
    const getCountersTask = getCounters(apiClient, storeId, moment(new Date(Date.now())).toDate())
    const isForecastingEnabledTask = isFeatureEnabled(apiClient, Feature.HotFoodBatches, storeId)

    Promise.all([getCountersTask, isForecastingEnabledTask])
      .then((responses: [ICounterCheck[], boolean]) => {
        const storedCabinets: ICounterCheck[] = []
        const storedShelves: ICounterCheck[] = []

        setIsForecastingEnabled(responses[1])

        responses[0]
          .filter((counter) => !counter.turboServe)
          .map((counter) => {
            storedCabinets.push({
              counterNumber: counter.counterNumber,
              isFaulty: counter.isFaulty,
              takenAt: counter.takenAt !== null ? counter.takenAt : undefined,
              temperatures: undefined,
              turboServe: false,
            })
          })

        responses[0]
          .filter((counter) => counter.turboServe)
          .map((counter) => {
            storedShelves.push({
              counterNumber: counter.counterNumber,
              isFaulty: counter.isFaulty,
              shelves: counter.shelves,
              takenAt: counter.takenAt !== null ? counter.takenAt : undefined,
              temperatures: undefined,
              turboServe: true,
            })
          })

        if (storedCabinets.length === 0) {
          setActiveTab('turbo-serve')
          setIsTurboServe(true)
        }

        if (storedCabinets.length > 0) {
          setHasServeOverTab(true)

          const updatedCabinets = storedCabinets.reduce(
            (accCabinets: ICounterCheck[], current: ICounterCheck) => [
              ...accCabinets,
              updateCounterCheckStatusByTime(current, false, current.takenAt),
            ],
            []
          )

          SetIsFirstCheck(hasAnyCounterToFirstCheckFrom(updatedCabinets, false))

          if (refreshCabinetsStatus) {
            updateCounterCardStatus(updatedCabinets, false)
          }
        }

        if (storedShelves.length > 0) {
          setHasTurboServeTab(true)

          const updatedShelves = storedShelves.reduce(
            (accShelves: ICounterCheck[], current: ICounterCheck) => [
              ...accShelves,
              updateCounterCheckStatusByTime(current, true, current.takenAt),
            ],
            []
          )

          // if turbo-serve only story,
          // update firstcheck status for turbo-serve because the first tab set to Turbo-serve
          if (storedCabinets.length === 0) {
            SetIsFirstCheck(hasAnyCounterToFirstCheckFrom(updatedShelves, true))
          }

          if (refreshShelvesStatus) {
            updateCounterCardStatus(updatedShelves, true)
          }
        }

        setIsLoading(false)

        setInterval(refresh, 10000)
      })
      .catch((e) => {
        setErrorMessage(`${ErrorMessage.CounterTemperatureView.FailedToLoad} - ${(e as Error).message}`)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    dispatch(setPlanner(PlannerName.HotFood))

    const getTutorialTokenTask = apiClient.getTutorialAccessToken()
    const isProbeEnabledTask = isFeatureEnabled(apiClient, Feature.HotFoodProbe, storeId)


    let script: HTMLScriptElement
    let probeEnabled = false

    Promise.all([getTutorialTokenTask, isProbeEnabledTask])
      .then((responses: [string | null, boolean]) => {
        setTutorialToken(responses[0])

        probeEnabled = responses[1]
        setHotFoodProbeFeature(probeEnabled)
        setIsBluetoothReading(probeEnabled)

        if (probeEnabled) {
          script = document.createElement('script')
          script.src = './probe.js'
          document.head.append(script)

          if ((window as Window).webkit && (window as Window).webkit!.messageHandlers.bluetoothMessage) {
            (window as Window).webkit!.messageHandlers.bluetoothMessage.postMessage('start')
          }
        }
      })
      .catch(() => setErrorMessage(ErrorMessage.CounterTemperatureView.FailedToLoad))

    loadDataProcess(true, true)

    return () => {
      if (probeEnabled) {
        document.head.removeChild(script)
        if ((window as Window).webkit && (window as Window).webkit!.messageHandlers.bluetoothMessage) {
          (window as Window).webkit!.messageHandlers.bluetoothMessage.postMessage('stop')
        }
      }
    }
  }, [apiClient, dispatch, storeId])

  // it's called by 1) initial loading and 2) reRendering
  const updateCounterCardStatus = (counters: ICounterCheck[], isTurbo: boolean) => {
    // update if turbo-serve or serve-over has any further action: temperature check after 2hours, or fix from faulty
    if (isTurbo) {
      // update main state variable of counter object
      setShelves(counters)

      // update the global variable to refer it on every refresh
      varShelves = counters

      // it will check if there is a remain action to take among cabinets
      setHasTurboServeAction(!isAllTemperatureChecked(counters) ||
        counters.filter((tc) => tc.isFaulty).length > 0)
    } else {
      setCabinets(counters)
      varCabinets = counters
      setHasServeOverAction(!isAllTemperatureChecked(counters) ||
        counters.filter((sc) => sc.isFaulty).length > 0)
    }
  }

  // 1) after new temp is added
  // 2) after new temp is deleted
  // 3) after tab is changed
  const reRenderUpdatedCounters = (counters: ICounterCheck[], isTurbo: boolean) => {
    updateCounterCardStatus(counters, isTurbo)

    SetIsFirstCheck(hasAnyCounterToFirstCheckFrom(counters, isTurbo))

    // not missing the usecases, reset flag here.
    setIsTurboServeInstantSubmissionMessage(false)
  }

  const onCloseFaultyOrFixedStillNotFixed = (isTurbo: boolean) => {
    // close Status dialog
    setFaultyOrFixedCabinet(undefined)

    // open "still not fixed" dialog
    if (isTurbo) {
      setAlertConfig({ alertKey: AlertMessageKey.ShelfUnder75DegTemperatureNotFixed, opened: true })
    } else {
      setAlertConfig({ alertKey: AlertMessageKey.CabinetUnder75DegTemperatureNotFixed, opened: true })
    }
  }

  const onSaveFaultyOrFixed = async (data: ICounterCheck) => {
    setFaultyOrFixedCabinet(undefined)

    if (data.isFaulty) {
      // user has marked as faulty
      await createCabinetStatus(apiClient, {
        counterNumber: data.counterNumber,
        isFaulty: data.isFaulty,
        storeId,
        turboServe: isTurboServe,
      }).then((res: IResult) => {
        if (res.isSuccess) {
          // mark cabinet as Faulty
          reRenderUpdatedCounters(updateCountersByFaulty(isTurboServe ? shelves : cabinets,
            data.counterNumber,
            data.isFaulty), isTurboServe)
        } else {
          setErrorMessage(`${ErrorMessage.SaveException}. ${res.message}`)
        }
      }).catch(() => {
        setErrorMessage(ErrorMessage.SaveException)
      })

    } else {

      // user has marked as fixed
      const createCabinetStatusTask = createCabinetStatus(apiClient, {
        counterNumber: data.counterNumber,
        isFaulty: data.isFaulty,
        storeId,
        turboServe: isTurboServe,
      })

      const createCounterTemperaturesTask = createCounterTemperatures(apiClient, {
        cabinetId: data.counterNumber,
        isBluetoothReading,
        isTurboServe,
        storeId,
        temperatures: data.temperatures!.reduce(
          (
            accTs: Array<{
              position: BatchTray | CabinetWindow | TurboShelf
              temperature: number
            }>,
            t
          ) => {
            if (t.temperature) {
              return [
                ...accTs,
                {
                  position: t.position,
                  temperature: Number(t.temperature),
                },
              ]
            }
            return accTs
          },
          []
        ),
      })

      Promise.all([createCabinetStatusTask, createCounterTemperaturesTask])
        .then((responses: [IResult, ICreateCounterTemperaturesResponse]) => {

          if (responses[0].isSuccess) {
            // mark cabinet as Fixed
            const counters = isTurboServe ? shelves : cabinets
            const updatedCounters = counters.reduce((accCounter: ICounterCheck[], current: ICounterCheck) => {
              if (current.counterNumber === data.counterNumber) {
                current.isFaulty = data.isFaulty
              }
              return [...accCounter, current]
            }, [])

            // update temperature taken at time
            const newCounter = responses[1].data as ICounterTemperature

            const ctr = updateCountersByTakenTime(
              updatedCounters,
              newCounter.counterNumber,
              newCounter.takenAt,
              isTurboServe
            )

            reRenderUpdatedCounters(ctr, isTurboServe)

          } else {
            setErrorMessage(`${ErrorMessage.SaveException}. ${responses[0].message}`)
          }
        }).catch(() => {
          setErrorMessage(ErrorMessage.SaveException)
        })
    }
  }

  const handleFaultyOrFixed = (data: ICounterCheck) => {
    setFaultyOrFixedCabinet(data)
  }

  const handleSubmit = async (counterNumber: number, data: ITemperatureData[], isBtReading: boolean) => {
    // if more than an input box is empty, invalid error message and just close
    const emptyTemps = data.filter((t) => t.temperature === '')
    if (emptyTemps.length > 0) {
      setAlertConfig({
        alertKey: AlertMessageKey.InValidInputData,
        counterNumber,
        isTurboServe,
        opened: true,
      })
      return
    }

    // temporary temperatures data for a modal box event data handling
    SetSubmitBufferData(data)

    const counter = getCounterByNumber(isTurboServe ? shelves : cabinets, counterNumber)
    if (!counter) {
      setAlertConfig({
        alertKey: AlertMessageKey.SomethingWrong,
        counterNumber,
        isTurboServe,
        opened: true,
      })
      return
    }

    const [isValid,  resultMessageType] = validCounterTemperatures(data, isTurboServe, counter)

    let showAlert = !isValid

    switch (resultMessageType) {
      case AlertMessageKey.TopShelfOverMaxTemperature:
      case AlertMessageKey.MiddleFirstShelfOverMaxTemperature:
      case AlertMessageKey.MiddleSecondShelfOverMaxTemperature:
      case AlertMessageKey.BottomShelfOverMaxTemperature:
        showAlert = true
    }

    if (showAlert) {
      setAlertConfig({
        alertKey: resultMessageType as AlertMessageKey,
        counterNumber,
        isTurboServe,
        opened: true,
      })
      return
    }

    setAlertConfig({ opened: false })

    // normal case -> proceed to save with validated temp data
    await handleSaveCounterTemperature(counterNumber, isTurboServe, data, false, isBtReading)

    // only handle the instant submission message after successfully saved.
    // this value MUST set after the above function 'handleSaveCounterTemperature'
    // because reRenderUpdateCounter will reset this flag to false.
    setIsTurboServeInstantSubmissionMessage(true)
    return
  }

  const refresh = () => {
    let refreshServeOver = true
    let refreshTurboServe = true

    const now = moment(new Date())

    const allServeOverTempsChecked = isAllTemperatureChecked(varCabinets.filter((tc) => !tc.isFaulty))
    const allTurboServeTempsChecked = isAllTemperatureChecked(varShelves.filter((tc) => !tc.isFaulty))

    // serve over, all counter temperatures taken for non-faulty cabinets?
    if (allServeOverTempsChecked) {
      varCabinets.filter((c) => !c.isFaulty).forEach((c) => {
        const takenTime = moment.utc(c.takenAt).local()
        if (now.diff(takenTime, 'hours', true) < 2.0) {
          refreshServeOver = false
        }
      })
    }

    // turbo serve, all counter temperatures taken for non-faulty cabinets?
    if (allTurboServeTempsChecked) {
      varShelves.filter((c) => !c.isFaulty).forEach((c) => {
        const takenTime = moment.utc(c.takenAt).local()
        if (now.get('hours') < afternoonHour || takenTime.get('hours') >= afternoonHour) {
          refreshTurboServe = false
        }
      })
    }

    if ((allServeOverTempsChecked && refreshServeOver) || (allTurboServeTempsChecked && refreshTurboServe)) {
      loadDataProcess(allServeOverTempsChecked && refreshServeOver, allTurboServeTempsChecked && refreshTurboServe)
    }
  }

  const handleSaveCounterTemperature = async (
    cabinetId: number,
    isTurbo: boolean,
    data: ITemperatureData[],
    isFaultyByApp: boolean,
    isBtReading: boolean) => {
    setIsLoading(true)

    await createCounterTemperatures(apiClient, {
      cabinetId,
      isBluetoothReading: isBtReading,
      isTurboServe: isTurbo,
      storeId,
      temperatures: data.reduce(
        (
          accTs: Array<{
            position: BatchTray | CabinetWindow | TurboShelf
            temperature: number
          }>,
          t
        ) => {
          if (t.temperature) {
            return [
              ...accTs,
              {
                position: t.position,
                temperature: Number(t.temperature),
              },
            ]
          }
          return accTs
        },
        []
      ),
    }).then((response: ICreateCounterTemperaturesResponse) => {
      setIsLoading(false)

      // if success, display a message card with time under the input card
      if (response.isSuccess) {
        const newCounter = response.data as ICounterTemperature
        const counters = updateCountersByTakenTime(
          isTurboServe ? shelves : cabinets,
          cabinetId,
          newCounter.takenAt,
          isTurboServe
        )

        // if the temp data is stored with insufficient values, it should be updated with a faulty on counter status
        const updatedCounters = (isFaultyByApp)
          ? updateCountersByFaulty(counters, cabinetId, isFaultyByApp)
          : counters

        reRenderUpdatedCounters(updatedCounters, isTurboServe)
      }

      if (response.isSuccess) {
        setErrorMessage('')
      } else {
        setErrorMessage(`${ErrorMessage.SaveException} : ${response.message}`)
      }
    }).catch(() => {
      setErrorMessage(ErrorMessage.SaveException)
    })
  }

  const updateCountersByFaulty = (
    targetCounters: ICounterCheck[],
    counterNumber: number,
    isFaulty: boolean) =>
    targetCounters.reduce((accCounter: ICounterCheck[], current: ICounterCheck) => {
      if (current.counterNumber === counterNumber) {
        current.isFaulty = isFaulty
      }
      return [...accCounter, current]
    }, [])

  const updateCountersByTakenTime = (
    counters: ICounterCheck[],
    counterNumber: number,
    takenTime: Date,
    isTurbo: boolean
  ) => {
    const updatedCounters = counters.reduce((accCounter: ICounterCheck[], current: ICounterCheck) => {
      if (current.counterNumber === counterNumber) {
        return [...accCounter, updateCounterCheckStatusByTime(current, isTurbo, takenTime)]
      }
      return [...accCounter, current]
    }, [])

    return updatedCounters
  }

  // according to an action of modal box, next event will be handled
  const handleAction = async () => {
    switch (alertConfig.alertKey) {
      case AlertMessageKey.CabinetUnder75DegTemperature:
      case AlertMessageKey.CabinetBetween63And55DegTemperature:
      case AlertMessageKey.CabinetBelow55DegTemperature:
      case AlertMessageKey.ShelfUnder75DegTemperature:
      case AlertMessageKey.ShelfBetween63And55DegTemperature:
      case AlertMessageKey.ShelfBelow55DegTemperature:
        await handleSaveCounterTemperature(
          alertConfig.counterNumber ? alertConfig.counterNumber : 0,
          isTurboServe,
          submitBufferData,
          true,
          isBluetoothReading
        )
        break

      case AlertMessageKey.TopShelfOverMaxTemperature:
      case AlertMessageKey.MiddleFirstShelfOverMaxTemperature:
      case AlertMessageKey.MiddleSecondShelfOverMaxTemperature:
      case AlertMessageKey.BottomShelfOverMaxTemperature:

        // normal case -> proceed to save with validated (albeit warmer) temp data
        await handleSaveCounterTemperature(
          alertConfig.counterNumber ? alertConfig.counterNumber : 0,
          isTurboServe,
          submitBufferData,
          false,
          isBluetoothReading
        )

        // only handle the instant submission message after successfully saved.
        // this value MUST set after the above function 'handleSaveCounterTemperature'
        // because reRenderUpdateCounter will reset this flag to false.
        setIsTurboServeInstantSubmissionMessage(true)
        break

      default:
        break
    }

    setAlertConfig({ opened: false })
  }

  const renderCounterCards = (counters: ICounterCheck[], isTurbo: boolean) => (
    counters.map((counter, i) => (
      <GridItem key={i} size={{ default: '1/1' }} className='ln-u-hard'>
        <CounterCard
          counterNumber={counter.counterNumber}
          isFaulty={counter.isFaulty}
          isTurboServe={isTurbo}
          tempCheckStatus={counter.checkedStatus}
          title={`${isTurbo ? 'Turbo-serve' : 'Cabinet'} ${counter.counterNumber}`}
          handleSubmit={handleSubmit}
          handleFaultyOrFixed={handleFaultyOrFixed}
          isFirstCheck={isFirstCheck}
          isBluetoothReading={isBluetoothReading}
          shelves={counter.shelves}
        />
      </GridItem>
    ))
  )

  if (hasServeOverTab) {
    tabs.push({ name: 'Cabinets', key: 'cabinets', testid: 'cabinet-tab' })
  }
  if (hasTurboServeTab) {
    tabs.push({ name: 'Turbo-serve', key: 'turbo-serve', testid: 'turbo-tab' })
  }

  const renderPage = () =>
    <div className='c-common-main-view-content'>
      <Header
        title={headerPlannerTitles.hotfood}
        navItems={headerNavigationItems}
        activeMenuType={Menu.CounterTemperature}
      />

      {/* Alert Modal box to do further actions: delete, save, close by the alertKeys */}
      {alertConfig.opened && (
        <CounterAlertModal
          isOpen={alertConfig.opened}
          alertKey={alertConfig.alertKey}
          handleClose={() => setAlertConfig({ opened: false })}
          handleAction={handleAction}
        />
      )}

      <Container soft className='ln-u-push-top-sm'>
        {isLoading && <Loading />}
        {errorMessage && <ErrorRefresh message={errorMessage} />}

        {!isLoading && !errorMessage && (
          <>
            <GridWrapper>
              <GridItem size={{ xs: '1/2', ss: '1/2', sm: '1/2', md: '1/2', lg: '1/2' }}>
                <h4>Counter temperature checks</h4>
                <div className='ln-u-body-1-fixed ln-u-push-bottom-sm'>
                  <p>Add temperature checks from cabinets and turbo-serves.</p>
                </div>
              </GridItem>

              { hotFoodProbeFeature &&
                <GridItem size={{ xs: '1/2', ss: '1/2', sm: '1/2', md: '1/2', lg: '1/2' }}>
                  <div className="c-availability-button">
                    { isBluetoothReading ?
                      <OutlinedButton data-testid='manual-input-modal-button' className='ln-u-margin-bottom*2'
                        onClick={() => setIsManualInputModalOpen(true)}
                      >
                        Change to manual checks
                      </OutlinedButton>
                      :
                      <OutlinedButton data-testid='bluetooth-input-button' className='ln-u-margin-bottom*2'
                        onClick={() => setIsBluetoothReading(true)}
                      >
                        Change back to Bluetooth checks
                      </OutlinedButton>
                    }
                    <div className='ln-u-body-1-fixed ln-u-push-bottom-sm'>
                      <span id='probe-status' data-testid='probe-status'></span>
                    </div>
                  </div>
                </GridItem>
              }
            </GridWrapper>

            {/* Tabs to change - default tab is cabinet*/}
            <Tabs>
              {tabs.map((tab) => (
                <TabLink
                  key={tab.key}
                  onClick={() => {
                    setActiveTab(tab.key)
                    setIsTurboServe(tab.key === 'turbo-serve')
                    reRenderUpdatedCounters(
                      tab.key === 'turbo-serve' ? shelves : cabinets,
                      tab.key === 'turbo-serve')
                  }}
                  active={activeTab === tab.key}
                  data-testid={tab.testid}
                >
                  {tab.name}
                </TabLink>
              ))}
            </Tabs>
            <TabPanel>
              {/* Display instruction on each cabinet or turbo-serve tab */}
              <CounterInstruction
                isFirstCheck={isFirstCheck}
                isTurboServe={isTurboServe}
                isTurboServeInputMode={hasTurboServeAction}
                isServeOverInputMode={hasServeOverAction}
              />

              {(isTurboServe && hasTurboServeAction) ||
                (!isTurboServe && hasServeOverAction)
                ? renderCounterCards(isTurboServe ? shelves : cabinets, isTurboServe)
                // : renderActionBanner(isTurboServe ? shelves : cabinets, isTurboServe)
                : <PostSubmitMessageCard
                  isTurboServe={isTurboServe}
                  counters={isTurboServe ? shelves : cabinets}
                  isInstantMessage={isTurboServeInstantSubmissionMessage} />
              }
            </TabPanel>
          </>
        )}

        {faultyOrFixedCabinet &&
          <CabinetStatusModal
            cabinet={faultyOrFixedCabinet}
            handleClose={() => setFaultyOrFixedCabinet(undefined)}
            handleCloseStillNotFixed={(isTurbo: boolean) => onCloseFaultyOrFixedStillNotFixed(isTurbo)}
            handleSave={onSaveFaultyOrFixed}
            isOpen={faultyOrFixedCabinet !== undefined}
            isBluetoothReading={isBluetoothReading}
          />
        }

        { isManualInputModalOpen &&
          <CounterManualInputModal
            handleClose={() => setIsManualInputModalOpen(false)}
            handleSave={(value) => {
              setIsBluetoothReading(value)
              setIsManualInputModalOpen(value)
            }}
            isOpen={isManualInputModalOpen}
          />
        }

      </Container>
    </div>

  // do not render TutorialWrapper if in test mode.  N.b. tutorialToken only exists if not in test mode...
  return (
    <>
      { !tutorialToken ? (
        <Loading message='Tutorial Loading' />
      ) : (
        <TutorialWrapper
          state={state}
          token={tutorialToken || ''}
          enabledFeatures={{forecasting: isForecastingEnabled || false}}
        >
          {renderPage()}
        </TutorialWrapper>
      )}
    </>
  )
}

export default CounterTemperatureView
